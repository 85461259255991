// SECTION 1
// Company URLS
export const ABOUT_US_URL = 'https://upstox.com/about/';
export const MARKET_TALK_URL = 'https://upstox.com/market-talk/';
export const BROKERAGE_CHARGES_URL = 'https://upstox.com/brokerage-charges/';
export const REFER_AND_EARN_URL = 'https://upstox.com/refer-and-earn/';
export const ANNOUNCEMENTS_URL = 'https://upstox.com/announcements/';
export const PARTNER_URL = 'https://upstox.com/become-sub-broker/';
export const PRESS_RELEASE_URL = 'https://upstox.com/press-releases/';
export const BUG_BOUNTY_URL = 'https://upstox.com/bug-bounty/';
export const LIFE_AT_UPSTOX_URL = 'https://upstox.com/life-at-upstox/';
export const CONTACT_US_URL = 'https://upstox.com/contact-us/';
export const MEDIA_KIT_URL = 'https://marketing-creative-and-docs.s3.ap-south-1.amazonaws.com/Upstox+Brand+guides+%26+Logo+Files.zip';
// Social URLS
export const FACEBOOK_URL = 'https://www.facebook.com/upstox/';
export const TWITTER_URL = 'https://twitter.com/upstox/';
export const YOUTUBE_URL = 'https://www.youtube.com/user/rksvindia/';
export const INSTAGRAM_URL = 'https://www.instagram.com/upstox.pro/';
export const TELEGRAM_URL = 'https://t.me/TheOfficialUpstox';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/upstox/';

// Partner Platforms URL
export const DARTSTOCK_URL =
  'https://upstox.com/products/partner-platform/dartstock/';
export const NEST_TRADER_URL = 'https://upstox.com/nest-desktop/';
export const FAX_TRADER_URL =
  'https://upstox.com/products/partner-platform/fox-trader/';

// Products URL
export const PRO_WEB_URL = 'https://upstox.com/upstox-pro-web-online-trading/';
export const PRO_MOBILE_URL =
  'https://upstox.com/upstox-pro-mobile-trading-app/';
export const MUTUAL_FUNDS_URL = 'https://upstox.com/mutual-funds/';
export const DEVELOPER_URL = 'https://upstox.com/developer/api-documentation';
export const UPSTOX_X_TRADING_VIEW_URL =
  'https://upstox.com/free-chart-view-for-live-trading/';

export const ALGO_LABS_URL =
  'https://upstox.com/products/amibroker-afl-bridge/';
export const IPOS_URL = 'https://upstox.com/ipo-initial-public-offering';
export const FUTURES_AND_OPTIONS_URL =
  'https://upstox.com/trade-in-futures-and-options/';
export const STOCKS_URL = 'https://upstox.com/stocks/';
export const INDICES_URL = 'https://upstox.com/indices/';

// Order Generator URL
export const BROKERAGE_CALCULATOR_URL =
  'https://upstox.com/calculator/brokerage-calculator/';
export const MARGIN_CALCULATOR_URL =
  'https://upstox.com/calculator/span-calculator/';
export const OPTION_STRATEGY_BUILDER_URL =
  'https://upstox.com/calculator/option-strategy-builder/';
export const ORDER_GENERATOR_URL =
  'https://upstox.com/tools-and-calculators/order-link-generator/';
export const MF_RETURNS_CALCULATOR_URL =
  'https://upstox.com/calculator/mutual-fund-return/';
export const SIP_CALCULATOR_URL =
  'https://upstox.com/calculator/sip-calculator/';
export const ELSS_CALCULATOR_URL = 'https://upstox.com/calculator/elss/';
export const SWP_CALCULATOR_URL = 'https://upstox.com/calculator/swp/';
export const LUMPSUM_CALCULATOR_URL = 'https://upstox.com/calculator/lumpsum/';
export const NPV_URL = 'https://upstox.com/calculator/npv/';
export const FUTURE_VALUE_URL = 'https://upstox.com/calculator/future-value/';
export const OPTION_VALUE_URL = 'https://upstox.com/calculator/option-value/';
export const NPS_CALCULATOR_URL = 'https://upstox.com/calculator/nps/';
export const ALL_CALCULATOR_URL = 'https://upstox.com/calculator/';
// SECTION 2
export const UPSTOX_FORM_URL = 'https://upstox.com/forms/';
export const TRADING_GLOSSARY_URL = 'https://upstox.com/glossary/';

// Help center URL
export const CHECK_LEDGER_URL =
  'https://upstox.com/help-center/user-account/checking-profit-and-loss-in-keystone/8177-how-do-i-check-my-ledger-in-the-back-office/';
export const DOWNLOAD_PL_URL =
  'https://upstox.com/help-center/user-account/checking-profit-and-loss-in-keystone/8176-how-do-i-view-and-download-my-profit-and-loss-statement-in-keystone/';
export const TRANSFER_FUNDS_URL =
  'https://upstox.com/help-center/user-account/funds-transfer-via-back-office-system/7924-what-are-the-different-ways-in-which-i-can-transfer-funds/';
export const PLACE_ORDER_WEB_URL =
  'https://upstox.com/help-center/pro-web/getting-started-pro-web/7980-place-an-order/';
export const ORDER_TYPES_WEB_URL =
  'https://upstox.com/help-center/pro-web/getting-started-pro-web/7982-possible-types-orders/';
export const TRACK_MOVEMENT_WEB_URL =
  'https://upstox.com/help-center/pro-web/getting-started-pro-web/7969-keep-track-market-movement-upstox-pro-web/';
export const WATCHLIST_OVERVIEW_URL =
  'https://upstox.com/help-center/pro-mobile/using-watchlists-on-my-phone/7942-overview-of-watchlists/';
export const ADDING_SCRIPTS_URL =
  'https://upstox.com/help-center/pro-mobile/using-watchlists-on-my-phone/7943-many-scrips-can-added-watchlist/';
export const TRACK_MOVEMENT_MOBILE_URL =
  'https://upstox.com/help-center/pro-mobile/getting-started-pro-mobile/7940-track-market-movement/';
export const MUTUAL_FUNDS_TYPES_URL =
  'https://upstox.com/help-center/mutual-funds/types-of-mutual-funds/7895-investing-in-different-types-of-mutual-funds/';
export const PLACING_MF_ORDER_URL =
  'https://upstox.com/help-center/mutual-funds/getting-started/7882-placing-a-mutual-fund-order/';
export const SIP_FUNDS_URL =
  'https://upstox.com/help-center/mutual-funds/ways-to-invest-in-mutual-funds/7901-know-funds-invest-via-sips-upstox-mf-platform/';
export const MARGIN_INFO_URL =
  'https://upstox.com/help-center/margins/frequently-asked-questions-margins/8099-how-much-margin-upstox-provide/';
export const MARGIN_LIMITS_URL =
  'https://upstox.com/help-center/margins/frequently-asked-questions-margins/7881-margin-limits/';
export const MTM_INFO_URL =
  'https://upstox.com/help-center/margins/margins-with-upstox/7889-mtm-square-off-percentage/';
export const ACCOUNT_OPENING_URL =
  'https://upstox.com/help-center/account-opening/getting-started-account-opening/8003-opening-account-using-aadhaar/';
export const IPV_URL =
  'https://upstox.com/help-center/account-opening/documents-required/8020-performing-in-person-verification/';
export const AO_CHARGES_URL =
  'https://upstox.com/help-center/account-opening/brokerage-and-account-opening-charges/8024-account-opening-fees-and-hidden-charges/';
export const CALCULATE_BROKERAGE_URL =
  'https://upstox.com/help-center/brokerage-charges/calculating-my-brokerage-fees/7909-calculate-brokerage/';
export const STATUTORY_CHARGES_URL =
  'https://upstox.com/help-center/brokerage-charges/calculating-my-brokerage-fees/8194-mandatory-statutory-charges-for-different-segments/';
export const GST_URL =
  'https://upstox.com/help-center/brokerage-charges/calculating-my-brokerage-fees/8141-calculating-gst/';
export const UPSTOX_API_URL =
  'https://upstox.com/help-center/api/getting-started-api/7972-what-is-upstox-api/';
export const AMIBROKER_AFL_URL =
  'https://upstox.com/help-center/api/getting-started-api/8179-what-is-amibroker-api/';
export const AMIBROKER_PLUGINS_URL =
  'https://upstox.com/help-center/api/getting-started-api/8723-enable-and-integrate-the-market-data-feed-plugin-into-amibroker/';
export const NEST_DOWNLOAD_URL =
  'https://upstox.com/help-center/desktop-nest/getting-started-desktop-nest/7931-download-install-nest-trader/';
export const NEST_INDEX_INFO_URL =
  'https://upstox.com/help-center/desktop-nest/blocked-account-and-other-queries/7934-index-bar-not-show-nest/';
export const UNBLOCK_NEST_ACCOUNT_URL =
  'https://upstox.com/help-center/desktop-nest/getting-started-desktop-nest/7932-reset-blocked-nest/';
export const ZERO_BROKERAGE_TRADING_URL =
  'https://upstox.com/help-center/trading-basics/frequently-asked-questions-trading-basics/8039-zero-brokerage-trading/';
export const INVESTING_TRADING_URL =
  'https://upstox.com/help-center/trading-basics/frequently-asked-questions-trading-basics/8052-difference-investing-trading-2/';

// Learnng Center URLs
export const TRADING_DEMAT_URL =
  'https://upstox.com/learning-center/demat-account/difference-between-demat-account-and-trading-account/';
export const DOC_REQUIREMENT_DEMAT_URL =
  'https://upstox.com/learning-center/demat-account/documents-required-for-a-demat-account/';
export const DEMAT_AO_URL =
  'https://upstox.com/learning-center/demat-account/demat-account-opening-procedure/';
export const DEMAT_TRADING_DIFF_URL =
  'https://upstox.com/learning-center/demat-account/difference-between-demat-account-and-trading-account/';
export const DOC_REQUIREMENT_TRADING_URL =
  'https://upstox.com/learning-center/trading-account/documents-required-to-open-a-trading-account/';
export const TRADING_AO_URL =
  'https://upstox.com/learning-center/trading-account/trading-account-opening-procedure/';
export const BENEFITS_TRADING_ACCOUNT_URL =
  'https://upstox.com/learning-center/trading-account/features-and-benefits-of-a-trading-account/';
export const ONLINE_TRADING_BENEFITS_URL =
  'https://upstox.com/learning-center/online-trading/benefits-of-online-trading/';
export const ONLINE_OFFLINE_TRADING_URL =
  'https://upstox.com/learning-center/online-trading/difference-between-online-and-offline-trading/';
export const FUNDAMENTAL_ANALYSIS_URL =
  'https://upstox.com/learning-center/online-trading/what-is-fundamental-analysis-and-how-to-do-it/';
export const INTRADAY_TRADING_URL =
  'https://upstox.com/learning-center/intraday-trading/what-is-intraday-trading/';
export const INTRADAY_DELIVERY_URL =
  'https://upstox.com/learning-center/intraday-trading/difference-between-intraday-and-delivery-trading/';
export const INTRADAY_TIPS_URL =
  'https://upstox.com/learning-center/intraday-trading/intraday-trading-tips-and-tricks/';
export const FUTURES_TRADING_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-futures-trading/';
export const TRADING_BENEFITS_FUTURES_URL =
  'https://upstox.com/learning-center/futures-and-options/benefits-of-trading-in-futures/';
export const MF_BENEFITS_URL =
  'https://upstox.com/learning-center/mutual-funds/benefits-of-investing-in-mutual-funds/';
export const NAV_URL =
  'https://upstox.com/learning-center/mutual-funds/what-is-nav-net-asset-value/';
export const ELSS_URL =
  'https://upstox.com/learning-center/mutual-funds/what-is-elss-and-how-to-invest-in-elss/';
export const STOCK_MARKET_GUIDE_URL =
  'https://upstox.com/learning-center/share-market/stock-market-guide-for-beginners/';
export const NSE_BSE_URL =
  'https://upstox.com/learning-center/share-market/what-is-nse-and-bse/';
export const EQUITY_BENIFITS_URL =
  'https://upstox.com/learning-center/share-market/benefits-of-equity-investment/';
export const IPO_URL =
  'https://upstox.com/learning-center/ipo/how-to-invest-in-an-ipo/';
export const IPO_ADVANTAGES_URL =
  'https://upstox.com/learning-center/ipo/what-are-the-advantages-of-an-ipo/';
export const IPO_PROCESS_URL =
  'https://upstox.com/learning-center/ipo/how-does-an-ipo-work/';
export const NRI_DEMAT_URL =
  'https://upstox.com/learning-center/nri/demat-account-for-nri/';
export const TRADING_ACCOUNT_TYPES_URL =
  'https://upstox.com/learning-center/nri/all-about-nre-nro-nri-trading-accounts-in-india/';
export const TRADING_BENEFITS_WITH_BROKERS_URL =
  'https://upstox.com/learning-center/stock-broker/benefits-of-trading-with-discount-brokers/';
export const DISCOUNT_BROKING_URL =
  'https://upstox.com/learning-center/stock-broker/why-discount-broking-is-popular-in-india/';
export const BROKERS_DIFF_URL =
  'https://upstox.com/learning-center/stock-broker/differences-between-traditional-brokers-and-discount-brokers/';

// Section 3
export const DEMAT_ACCOUNT_URL =
  'https://upstox.com/open-demat-account/?landing_page=home';
export const TRADING_ACCOUNT_URL = 'https://upstox.com/trading-account/';
export const COMMODITY_TRADING_URL = 'https://upstox.com/commodity-trading/';
export const EQUITY_TRADING_URL = 'https://upstox.com/equity-trading/';
export const NRI_TRADING_URL =
  'https://upstox.com/learning-center/nri/all-about-nre-nro-nri-trading-accounts-in-india/';
export const SEBI_SCORE_URL = 'https://scores.gov.in/scores/Welcome.html';
export const UPSTOX_PRODUCTS_URL = 'https://upstox.com/products/';
export const DOWNLOAD_UPSTOX_URL = 'https://bv7np.app.goo.gl/yNFL';
export const UPSTOX_BASE_URL = 'https://upstox.com/';
export const DOWNLOAD_FORMS_URL = 'https://upstox.com/forms/';
export const MARGIN_SHEETS_URL =
  'https://www.dropbox.com/sh/5swgzb09l8t501r/AAD9VKUxJ4S5Z296UiD_qrH_a?dl=0';
export const GLOSSARY_URL = 'https://upstox.com/glossary/';
export const HELP_CENTER_URL = 'https://help.upstox.com/support/home/';

//More
export const STOCK_MARKET_HOLIDAYS_URL =
  'https://upstox.com/stocks-market/nse-bse-share-market-holiday-calendar-2022-india/';
export const INVEST_URL = 'https://upstox.com/invest-in-share-market/';
export const NSE_OIL_AND_GAS_SECTOR_STOCKS_URL =
  'https://upstox.com/stocks-market/nse-oil-and-gas-sector/';
export const NSE_BANKING_STOCKS_URL =
  'https://upstox.com/stocks-market/nse-banking-finance-sector/';
export const TOP_GAINERS_URL = 'https://upstox.com/stocks-market/top-gainers/';
export const TRADE_URL = 'https://upstox.com/trade-in-futures-and-options/';
export const NSE_IT_STOCKS_URL =
  'https://upstox.com/stocks-market/nse-information-technology-sector/';
export const MARGIN_TRADING_FACILITY_URL =
  'https://upstox.com/margin-trading-facility/';
export const TOP_LOSERS_URL = 'https://upstox.com/stocks-market/top-losers/';
export const NSE_CEMENT_STOCKS_URL =
  'https://upstox.com/stocks-market/nse-cement-construction-sector/';
export const NSE_METAL_STOCKS_URL =
  'https://upstox.com/stocks-market/nse-metal-mining-sector/';
export const OPTION_STRATEGIES_URL = 'https://upstox.com/option-strategies/';
export const STOCK_URL = 'https://upstox.com/stocks/';
export const NSE_AUTOMIBILES_STOCKS_URL =
  'https://upstox.com/stocks-market/nse-automobile-automotive-sector/';
export const NSE_PHARMA_STOCKS_URL =
  'https://upstox.com/stocks-market/nse-pharmaceuticals-sector/';
export const SHARE_MARKET_LISTED_COMPANY_IN_INDIA_URL =
  'https://upstox.com/stocks-market/share-market-listed-company-in-india/';

// Section 4
export const PRIVACY_POLICY_URL =
  'https://upstox.com/terms-of-use-and-privacy-policy/';
export const CAREERS_URL = 'https://jobs.lever.co/upstox';

// IPO Footer Links

export const IPO_SEO_URL = 'https://upstox.com/ipo-initial-public-offering';
export const LIC_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/lic-life-insurance-corporation-of-india/42cb1060d6504ae9930f8c595b35a019';
export const MOBIWIK_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/one-mobikwik-systems-limited/b00eba16b9464b5489db1c40253f4829';
export const EMCURE_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/emcure-pharmaceuticals-limited/4963b5bc3cdc4b5d899d153d4ead8561';
export const AIRLINES_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/go-airlines-india-limited/daae0b22d4364492be6ea3ed70aa16e2';
export const OYO_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/oyo-oravel-stays-limited/3736520e15c54938a3cc3adb29748e1c';
export const PRUDENT_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/prudent-corporate-advisory-services-limited/d6590b351e0746deb5a627519462095e';
export const WELNESS_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/wellness-forever-medicare-limited/cadd5f2f4ff24e898395d588db5d2474';
export const EMUDHRA_IPO_URL =
  'https://upstox.com/ipo-initial-public-offering/emudhra-limited/0ec13d00622045cc8e94d1a85e4017f4';

//Header Menu Url
export const INVEST_IPO_URL = 'https://invest.upstox.com/ipo/';
export const NEW_IPO_URL = 'https://upstox.com/ipo-initial-public-offering';

export const LEARNING_CENTER_DEMAT_URL =
  'https://upstox.com/learning-center/demat-account/';
export const LEARNING_CENTER_TRADING_URL =
  'https://upstox.com/learning-center/trading-account/';
export const LEARNING_CENTER_ONLINE_URL =
  'https://upstox.com/learning-center/online-trading/';
export const LEARNING_CENTER_INTRADAY_URL =
  'https://upstox.com/learning-center/intraday-trading/';
export const LEARNING_CENTER_OPTIONS_URL =
  'https://upstox.com/learning-center/futures-and-options/';
export const LEARNING_CENTER_MF_URL =
  'https://upstox.com/learning-center/mutual-funds/';
export const LEARNING_CENTER_SHAREMARKET_URL =
  'https://upstox.com/learning-center/share-market/';
export const LEARNING_CENTER_IPO_URL =
  'https://upstox.com/learning-center/ipo/';

export const HELP_CENTER_AO =
  'https://help.upstox.com/support/solutions/162081';
export const HELP_CENTER_POSTTRADE =
  'https://help.upstox.com/support/solutions/162101';
export const HELP_CENTER_HELPDESK =
  'https://help.upstox.com/support/tickets/new';

export const RESOURCES_KNOWLEDGE = 'https://upstox.com/knowledge-base/';
export const INVEST_CHART_URL =
  'https://upstox.com/files/sebi-investor-charter.pdf';
export const HOLIDAY_CALENDAR_URL =
  'https://upstox.com/stocks-market/nse-bse-share-market-holiday-calendar-2022-india/';
export const MUTUAL_FUNDS_ALPHABATIC_BASE_URL =
  'https://upstox.com/mutual-funds/';
export const STOCKS_ALPHABATIC_BASE_URL =
  'https://upstox.com/stocks-market/share-market-listed-company-in-india/';

// App Download Url

export const GOOGLE_PLAY_STORE_APP_DOWNLOAD_URL =
  'https://play.google.com/store/apps/details?id=in.upstox.app';
export const APPLE_APP_STORE_APP_DOWNLOAD_URL =
  'https://apps.apple.com/in/app/upstox-new-stocks-ipo-mf/id1584953620';

// FNO 1

export const NSE_OPTION_CHAIN = 'https://upstox.com/option-chain/nifty/';
export const HDFC_BANK_OPTION_CHAIN =
  'https://upstox.com/option-chain/hdfc-bank-ltd/';
export const TATA_STEEL_OPTION_CHAIN =
  'https://upstox.com/option-chain/tata-steel-limited/';
export const WIPRO_OPTION_CHAIN = 'https://upstox.com/option-chain/wipro-ltd/';
export const COAL_INDIA_OPTION_CHAIN =
  'https://upstox.com/option-chain/coal-india-ltd/';
export const PNB_OPTION_CHAIN =
  'https://upstox.com/option-chain/punjab-national-bank/';
export const SAIL_OPTION_CHAIN =
  'https://upstox.com/option-chain/steel-authority-of-india/';
export const NTPC_OPTION_CHAIN = 'https://upstox.com/option-chain/ntpc-ltd/';
export const ITC_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/itc-ltd-future-share-price/';
export const HDFC_BANK_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/hdfc-bank-ltd-future-share-price/';

// FNO 2
export const BANKNIFTY_OPTION_CHAIN =
  'https://upstox.com/option-chain/banknifty/';
export const TCS_OPTION_CHAIN =
  'https://upstox.com/option-chain/tata-consultancy-serv-lt/';
export const SBI_OPTION_CHAIN =
  'https://upstox.com/option-chain/state-bank-of-india/';
export const AXIS_BANK_OPTION_CHAIN =
  'https://upstox.com/option-chain/axis-bank-limited/';
export const DLF_OPTION_CHAIN = 'https://upstox.com/option-chain/dlf-limited/';
export const RIL_OPTION_CHAIN =
  'https://upstox.com/option-chain/reliance-industries-ltd/';
export const ASIAN_PAINTS_OPTION_CHAIN =
  'https://upstox.com/option-chain/asian-paints-limited/';
export const NIFTY_CALL_OPTION =
  'https://upstox.com/options-live/nifty-call-put-option-price/';
export const TATA_MOTERS_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/tata-motors-limited-future-share-price/';
export const DLF_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/dlf-limited-future-share-price/';

// FNO 3
export const RELIANCE_OPTION_CHAIN =
  'https://upstox.com/option-chain/reliance-industries-ltd/';
export const INFOSYS_OPTION_CHAIN =
  'https://upstox.com/option-chain/infosys-limited/';
export const MARUTI_OPTION_CHAIN =
  'https://upstox.com/option-chain/maruti-suzuki-india-ltd/';
export const HINDALCO_OPTION_CHAIN =
  'https://upstox.com/option-chain/hindalco-industries-ltd/';
export const BAJAJ_FINANCE_OPTION_CHAIN =
  'https://upstox.com/option-chain/bajaj-finance-limited/';
export const BHARTI_AIRTEL_OPTION_CHAIN =
  'https://upstox.com/option-chain/bharti-airtel-limited/';
export const BANKNIFTY_CALL_OPTION =
  'https://upstox.com/options-live/banknifty-call-put-option-price/';
export const TATA_STEEL_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/tata-steel-limited-future-share-price/';
export const ICICI_BANK_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/icici-bank-ltd-future-share-price/';

// FNO 4
export const ITC_OPTION_CHAIN = 'https://upstox.com/option-chain/itc-ltd/';
export const TATA_MOTERS_OPTION_CHAIN =
  'https://upstox.com/option-chain/tata-motors-limited/';
export const TATA_POWER_OPTION_CHAIN =
  'https://upstox.com/option-chain/tata-power-co-ltd/';
export const ICICI_BANK_OPTION_CHAIN =
  'https://upstox.com/option-chain/icici-bank-ltd/';
export const HINDUSTAN_UNILIVER_OPTION_CHAIN =
  'https://upstox.com/option-chain/hindustan-unilever-ltd/';
export const IRCTC_OPTION_CHAIN =
  'https://upstox.com/option-chain/indian-rail-tour-corp-ltd/';
export const CIPLA_OPTION_CHAIN = 'https://upstox.com/option-chain/cipla-ltd/';
export const NIFTY_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/nifty-future-share-price/';
export const INFOSYS_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/infosys-limited-future-share-price/';
export const WIPRO_FUTURE_SHARE_PRICE =
  'https://upstox.com/futures-live/wipro-ltd-future-share-price/';

// INDIAN INDICES
export const NIFTY_50_SHARE_PRICE =
  'https://upstox.com/indices/nifty-50-share-price/';
export const NIFTY_NEXT_50_SHARE_PRICE =
  'https://upstox.com/indices/nifty-next-50-share-price/';
export const NIFTY_BANK_SHARE_PRICE =
  'https://upstox.com/indices/nifty-bank-share-price/';
export const NIFTY_100_SHARE_PRICE =
  'https://upstox.com/indices/nifty-100-share-price/';
export const NIFTY_200_SHARE_PRICE =
  'https://upstox.com/indices/nifty-200-share-price/';
export const NIFTY_500_SHARE_PRICE =
  'https://upstox.com/indices/nifty-500-share-price/';
export const NIFTY_MIDCAP_50_SHARE_PRICE =
  'https://upstox.com/indices/nifty-midcap-50-share-price/';
export const NIFTY_SMLCAP_50_SHARE_PRICE =
  'https://upstox.com/indices/nifty-smlcap-50-share-price/';
export const INDIA_VIX_SHARE_PRICE =
  'https://upstox.com/indices/india-vix-share-price/';
export const SENSEX_SHARE_PRICE =
  'https://upstox.com/indices/sensex-share-price/';
export const BSE100_SHARE_PRICE =
  'https://upstox.com/indices/bse100-share-price/';
export const LRGCAP_SHARE_PRICE =
  'https://upstox.com/indices/lrgcap-share-price/';
export const MIDCAP_SHARE_PRICE =
  'https://upstox.com/indices/midcap-share-price/';
export const SMLCAP_SHARE_PRICE =
  'https://upstox.com/indices/smlcap-share-price/';
export const BSE500_SHARE_PRICE =
  'https://upstox.com/indices/bse500-share-price/';
export const ALLCAP_SHARE_PRICE =
  'https://upstox.com/indices/allcap-share-price/';
export const BSE200_SHARE_PRICE =
  'https://upstox.com/indices/bse200-share-price/';
export const BSEIPO_SHARE_PRICE =
  'https://upstox.com/indices/bseipo-share-price/';
export const SMEIPO_SHARE_PRICE =
  'https://upstox.com/indices/smeipo-share-price/';

//  Popular Stocks

export const TATA_MOTORS_SHARE_PRICE =
  'https://upstox.com/stocks/tata-motors-limited-share-price/INE155A01022/';
export const ITC_SHARE_PRICE =
  'https://upstox.com/stocks/itc-ltd-share-price/INE154A01025/';
export const YES_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/yes-bank-limited-share-price/INE528G01035/';
export const HDFC_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/hdfc-ltd-share-price/INE001A01036/';
export const BHEL_SHARE_PRICE =
  'https://upstox.com/stocks/bhel-share-price/INE257A01026/';
export const ONGC_SHARE_PRICE =
  'https://upstox.com/stocks/oil-and-natural-gas-corp-share-price/INE213A01029/';
export const ADANI_PORT_SHARE_PRICE =
  'https://upstox.com/stocks/adani-port-sez-ltd-share-price/INE742F01042/';
export const VODAFONE_IDEA_SHARE_PRICE =
  'https://upstox.com/stocks/vodafone-idea-limited-share-price/INE669E01016/';
export const VEDANTA_SHARE_PRICE =
  'https://upstox.com/stocks/vedanta-limited-share-price/INE205A01025/';
export const CIPLA_SHARE_PRICE =
  'https://upstox.com/stocks/cipla-ltd-share-price/INE059A01026/';
export const TATA_CHEMICALS_SHARE_PRICE =
  'https://upstox.com/stocks/tata-chemicals-ltd-share-price/INE092A01019/';
export const MARUTI_SHARE_PRICE =
  'https://upstox.com/stocks/nbcc-india-limited-share-price/INE095N01031/';
export const ASIAN_PAINTS_SHARE_PRICE =
  'https://upstox.com/stocks/asian-paints-limited-share-price/INE021A01026/';
export const GAIL_SHARE_PRICE =
  'https://upstox.com/stocks/gail-india-ltd-share-price/INE129A01019/';
export const DLF_SHARE_PRICE =
  'https://upstox.com/stocks/dlf-limited-share-price/INE271C01023/';

export const IRCTC_SHARE_PRICE =
  'https://upstox.com/stocks/indian-rail-tour-corp-ltd-share-price/INE335Y01020/';
export const TATA_POWER_SHARE_PRICE =
  'https://upstox.com/stocks/tata-power-co-ltd-share-price/INE245A01021/';
export const INFOSYS_SHARE_PRICE =
  'https://upstox.com/stocks/infosys-limited-share-price/INE009A01021/';
export const TCS_SHARE_PRICE =
  'https://upstox.com/stocks/tata-consultancy-serv-lt-share-price/INE467B01029/';
export const WIPRO_SHARE_PRICE =
  'https://upstox.com/stocks/wipro-ltd-share-price/INE075A01022/';
export const SUZLON_SHARE_PRICE =
  'https://upstox.com/stocks/suzlon-energy-limited-share-price/INE040H01021/';
export const BAJA_FINANCE_SHARE_PRICE =
  'https://upstox.com/stocks/bajaj-finance-limited-share-price/INE296A01024/';
export const ASHOK_LELAND_SHARE_PRICE =
  'https://upstox.com/stocks/ashok-leyland-ltd-share-price/INE208A01029/';
export const IEX_SHARE_PRICE =
  'https://upstox.com/stocks/indian-energy-exc-ltd-share-price/INE022Q01020/';
export const AXIS_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/axis-bank-limited-share-price/INE238A01034/';
export const DMART_SHARE_PRICE =
  'https://upstox.com/stocks/avenue-supermarts-limited-share-price/INE192R01011/';
export const NBCC_SHARE_PRICE =
  'https://upstox.com/stocks/nbcc-india-limited-share-price/INE095N01031/';
export const CDSL_SHARE_PRICE =
  'https://upstox.com/stocks/central-depo-ser-i-ltd-share-price/INE736A01011/';
export const RELIANCE_POWER_SHARE_PRICE =
  'https://upstox.com/stocks/reliance-power-ltd-share-price/INE614G01033/';
export const BANK_OF_BARODA_SHARE_PRICE =
  'https://upstox.com/stocks/bank-of-baroda-share-price/INE028A01039/';

export const SBI_SHARE_PRICE =
  'https://upstox.com/stocks/state-bank-of-india-share-price/INE062A01020/';
export const LIC_SHARE_PRICE =
  'https://upstox.com/stocks/life-insura-corp-of-india-share-price/INE0J1Y01017/';
export const PNB_SHARE_PRICE =
  'https://upstox.com/stocks/punjab-national-bank-share-price/INE160A01022/';
export const ADANI_GREEN_SHARE_PRICE =
  'https://upstox.com/stocks/adani-green-energy-ltd-share-price/INE364U01010/';
export const IRFC_SHARE_PRICE =
  'https://upstox.com/stocks/indian-railway-fin-corp-l-share-price/INE053F01010/';
export const HFCL_SHARE_PRICE =
  'https://upstox.com/stocks/hfcl-limited-share-price/INE548A01028/';
export const HAPPIEST_MINDS_SHARE_PRICE =
  'https://upstox.com/stocks/happiest-minds-techno-ltd-share-price/INE419U01012/';
export const IOC_SHARE_PRICE =
  'https://upstox.com/stocks/indian-oil-corp-ltd-share-price/INE242A01010/';
export const CANARA_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/canara-bank-share-price/INE476A01022/';
export const PAYTM_SHARE_PRICE =
  'https://upstox.com/stocks/one-97-communications-ltd-share-price/INE982J01020/';
export const NALCO_SHARE_PRICE =
  'https://upstox.com/stocks/national-aluminium-co-ltd-share-price/INE139A01034/';
export const JSW_STEEL_SHARE_PRICE =
  'https://upstox.com/stocks/jsw-steel-limited-share-price/INE019A01038/';
export const HINDALCO_SHARE_PRICE =
  'https://upstox.com/stocks/hindalco-industries-ltd-share-price/INE038A01020/';
export const IFC_FIRST_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/idfc-first-bank-limited-share-price/INE092T01019/';
export const MRF_SHARE_PRICE =
  'https://upstox.com/stocks/mrf-ltd-share-price/INE883A01011/';

export const RELIANCE_SHARE_PRICE =
  'https://upstox.com/stocks/reliance-industries-ltd-share-price/INE002A01018/';
export const ADANI_POWER_SHARE_PRICE =
  'https://upstox.com/stocks/adani-power-ltd-share-price/INE814H01011/';
export const TATA_STEEL_SHARE_PRICE =
  'https://upstox.com/stocks/tata-steel-limited-share-price/INE081A01012/';
export const SAIL_SHARE_PRICE =
  'https://upstox.com/stocks/steel-authority-of-india-share-price/INE114A01011/';
export const ZOMATO_SHARE_PRICE =
  'https://upstox.com/stocks/zomato-limited-share-price/INE758T01015/';
export const BPCL_SHARE_PRICE =
  'https://upstox.com/stocks/bharat-petroleum-corp-lt-share-price/INE029A01011/';
export const SBI_CARD_SHARE_PRICE =
  'https://upstox.com/stocks/sbi-cards-pay-ser-ltd-share-price/INE018E01016/';
export const ICICI_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/icici-bank-ltd-share-price/INE090A01021/';
export const COAL_INDIA_SHARE_PRICE =
  'https://upstox.com/stocks/coal-india-ltd-share-price/INE522F01014/';
export const HUL_SHARE_PRICE =
  'https://upstox.com/stocks/hindustan-unilever-ltd-share-price/INE030A01027/';
export const INDUSIND_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/indusind-bank-limited-share-price/INE095A01012/';
export const SUBEX_SHARE_PRICE =
  'https://upstox.com/stocks/subex-ltd-share-price/INE754A01055/';
export const L_AND_T_SHARE_PRICE =
  'https://upstox.com/stocks/larsen-toubro-ltd-share-price/INE018A01030/';
export const BANDHAN_BANK_SHARE_PRICE =
  'https://upstox.com/stocks/bandhan-bank-limited-share-price/INE545U01014/';

//Calculator

export const BROKERAGE_CALCULATOR =
  'https://upstox.com/calculator/brokerage-calculator/';
export const NPV_CALCULATOR = 'https://upstox.com/calculator/npv/';
export const OPTION_VALUE_CALCULATOR =
  'https://upstox.com/calculator/option-value/';
export const NSC_CALCULATOR =
  'https://upstox.com/calculator/nsc-national-savings-certificate/';
export const GRATUITY_CALCULATOR = 'https://upstox.com/calculator/gratuity/';
export const HRA_CALCULATOR =
  'https://upstox.com/calculator/hra-house-rent-allowance-calculator/';
export const HDFC_FD_CALCULATOR =
  'https://upstox.com/calculator/hdfc-fd-fixed-deposit-interest-rate/';
export const YES_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/yes-fd-fixed-deposit-interest-rate/';
export const RBL_FD_CALCULATOR =
  'https://upstox.com/calculator/rbl-fd-fixed-deposit-interest-rate/';
export const KOTAK_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/kotak-fd-fixed-deposit-interest-rate/';
export const KARNATAKA_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/karnataka-fd-fixed-deposit-interest-rate/';

export const MARGIN_CALCULATOR =
  'https://upstox.com/calculator/span-calculator/';
export const FUTURE_VALUE_CALCULATOR =
  'https://upstox.com/calculator/future-value/';
export const NPS_CALCULATOR = 'https://upstox.com/calculator/nps/';
export const SUKANYA_SAMRIDDHI_YOJANA_CALCULATOR =
  'https://upstox.com/calculator/sukanya-samriddhi-yojana/';
export const SIMPLE_INTEREST_CALCULATOR =
  'https://upstox.com/calculator/simple-interest/';
export const ICICI_FD_CALCULATOR =
  'https://upstox.com/calculator/icici-fd-fixed-deposit-interest-rate/';
export const INDIAN_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/indian-bank-fd-fixed-deposit-interest-rate/';
export const SBI_FD_CALCULATOR =
  'https://upstox.com/calculator/sbi-fd-fixed-deposit-interest-rate/';
export const UCO_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/uco-fd-fixed-deposit-interest-rate/';
export const IDFC_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/idfc-fd-fixed-deposit-interest-rate/';

export const MUTUAL_FUND_RETURNS_CALCULATOR =
  'https://upstox.com/calculator/mutual-fund-return/';
export const SWP_CALCULATOR = 'https://upstox.com/calculator/swp/';
export const PPF_CALCULATOR =
  'https://upstox.com/calculator/ppf-public-provident-fund/';
export const COMPOUND_INTEREST_CALCULATOR =
  'https://upstox.com/calculator/compound-interest/';
export const FD_CALCULATOR = 'https://upstox.com/calculator/fd-fixed-deposit/';
export const CANARA_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/canara-fd-fixed-deposit-interest-rate/';
export const BANDHAN_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/bandhan-fd-fixed-deposit-interest-rate/';
export const BAJAJ_FINSERV_FD_CALCULATOR =
  'https://upstox.com/calculator/bajaj-finserv-fd-fixed-deposit-interest-rate/';
export const CENTERAL_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/central-fd-fixed-deposit-interest-rate/';
export const IDBI_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/idbi-fd-fixed-deposit-interest-rate/';

export const SIP_CALCULATOR = 'https://upstox.com/calculator/sip-calculator/';
export const ELSS_CALCULATOR = 'https://upstox.com/calculator/elss/';
export const CAGR_CALCULATOR =
  'https://upstox.com/calculator/cagr-calculate-compound-annual-growth-rate/';
export const APY_CALCULATOR =
  'https://upstox.com/calculator/atal-pension-yojana-apy/';
export const GST_CALCULATOR = 'https://upstox.com/calculator/gst/';
export const AXIS_FD_CALCULATOR =
  'https://upstox.com/calculator/axis-fd-fixed-deposit-interest-rate/';
export const UNION_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/union-fd-fixed-deposit-interest-rate/';
export const INDUSIND_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/indusind-bank-fd-fixed-deposit-interest-rate/';
export const FEDERAL_BANK_FD_CALCULATOR =
  'https://upstox.com/calculator/federal-fd-fixed-deposit-interest-rate/';
export const BOM_FD_CALCULATOR =
  'https://upstox.com/calculator/bank-of-maharashtra-fd-fixed-deposit-interest-rate/';
export const INCOME_TAX_CALCULATOR= 'https://upstox.com/calculator/income-tax-calculator/';

// Banking

export const HDFC_NET_BANKING_URL =
  'https://upstox.com/banking/hdfc-bank-net-banking/';
export const AXIS_NET_BANKING_URL =
  'https://upstox.com/banking/axis-bank-net-banking/';
export const UNION_NET_BANKING_URL =
  'https://upstox.com/banking/union-bank-netbanking/';
export const BOI_NET_BANKING_URL =
  'https://upstox.com/banking/bank-of-india-netbanking/';
export const IMPS_FULL_FORM_URL =
  'https://upstox.com/banking/what-is-the-imps-full-form/';
export const CHEQUE_NUMBER_URL = 'https://upstox.com/banking/cheque-number/';
export const BANKERS_CHEQUE_URL =
  'https://upstox.com/banking/what-is-bankers-cheque/';
export const PNB_SMS_BANKING_URL =
  'https://upstox.com/banking/punjab-national-bank-pnb-sms-banking/';

export const SBI_NET_BANKING_URL =
  'https://upstox.com/banking/state-bank-of-india-sbi-net-banking/';
export const CBI_NET_BANKING_URL =
  'https://upstox.com/banking/central-bank-of-india-cbi-net-banking/';
export const NET_BANKING_URL =
  'https://upstox.com/banking/what-is-net-banking/';
export const AXIS_BANK_NET_BANKING_URL =
  'https://upstox.com/banking/how-to-login-axis-bank-netbanking/';
export const CANARA_BANK_NET_BANKING_URL =
  'https://upstox.com/banking/canara-bank-net-banking/';
export const AXIS_BANK_CORPORATE_NET_BANKING_URL =
  'https://upstox.com/banking/axis-bank-corporate-netbanking/';
export const RTGS_TIMINGS_URL =
  'https://upstox.com/banking/what-are-the-rtgs-timings/';
export const DCB_BANK_RTGS_FORM =
  'https://upstox.com/banking/how-to-fill-dcb-bank-rtgs-form/';

export const PNB_NET_BANKING_URL =
  'https://upstox.com/banking/pnb-net-banking/';
export const BOB_NET_BANKING_URL =
  'https://upstox.com/banking/bank-of-baroda-bob-net-banking/';
export const ICICI_CORP_NET_BANKING_URL =
  'https://upstox.com/banking/icici-corporate-netbanking/';
export const RTGS_FULL_FORM_URL =
  'https://upstox.com/banking/what-is-the-rtgs-full-form/';
export const SBI_MINI_STATEMENT_URL =
  'https://upstox.com/banking/how-to-get-sbi-mini-statement/';
export const PNB_CORPORATE_BANKING_URL =
  'https://upstox.com/banking/punjab-national-bank-pnb-corporate-banking/';
export const RBI_HOLIDAYS_URL =
  'https://upstox.com/banking/reserve-bank-of-india-rbi-holidays-2022/';

export const INDIAN_BANK_NET_BANKING_URL =
  'https://upstox.com/banking/indian-bank-net-banking/';
export const SBI_CORPORATE_BANKING_URL =
  'https://upstox.com/banking/state-bank-of-india-sbi-corporate-banking/';
export const KVB_NET_BANKING_URL =
  'https://upstox.com/banking/karur-vysya-bank-kvb-netbanking/';
export const IDBI_NET_BANKING_LOGIN_URL =
  'https://upstox.com/banking/how-to-login-idbi-bank-netbanking/';
export const NEFT_TIMINGS_URL =
  'https://upstox.com/banking/what-are-the-neft-transfer-timings/';
export const ALLAHABAD_BANK_NET_BANKING_URL =
  'https://upstox.com/banking/allahabad-bank-netbanking/';
export const TELEGRAPHIC_TRANSFER_URL =
  'https://upstox.com/banking/what-is-telegraphic-transfer/';

// SavingAccount

export const KOTAK_NET_BANKING_URL =
  'https://upstox.com/savings-account/kotak-mahindra-bank-net-banking/';
export const DEMONETISATION_URL =
  'https://upstox.com/savings-account/what-is-demonetisation-meaning/';
export const HDFC_MOBILE_BANKING_URL =
  'https://upstox.com/savings-account/hdfc-bank-mobile-banking/';
export const IRCTC_EWALLET_URL =
  'https://upstox.com/savings-account/irctc-ewallets/';
export const AXIS_MOBILE_BANKING_URL =
  'https://upstox.com/savings-account/axis-bank-mobile-banking/';
export const CANARA_BANK_SAVINGS_ACCOUNT_URL =
  'https://upstox.com/savings-account/canara-bank-savings-account/';
export const BANDHAN_BANK_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/bandhan-bank-savings-account/';
export const PNB_VIDYARTHI_SF_ACCOUNT_URL =
  'https://upstox.com/savings-account/punjab-national-bank-pnb-vidyarthi-sf-account/';

export const CANCELLED_CHEQUE_URL =
  'https://upstox.com/savings-account/cancelled-cheque/';
export const CITI_BANK_NET_BANKING_URL =
  'https://upstox.com/savings-account/citibank-net-banking/';
export const SBI_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/sbi-state-bank-of-india-saving-account/';
export const HDFC_BANK_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/hdfc-bank-savings-account/';
export const PNB_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/punjab-national-bank-pnb-savings-account/';
export const FEDERAL_BANK_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/federal-bank-savings-account/';
export const HDFC_SMS_BANKING_URL =
  'https://upstox.com/savings-account/hdfc-bank-sms-banking/';
export const CBI_SAVING_ACCOUNTS_URL =
  'https://upstox.com/savings-account/central-bank-of-india-savings-account/';

export const KOTAK_811_URL =
  'https://upstox.com/savings-account/kotak-811-saving-account/';
export const SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/what-is-savings-account/';
export const AXIS_BANK_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/axis-bank-savings-account/';
export const SBI_SALARY_ACCOUNT_URL =
  'https://upstox.com/savings-account/sbi-salary-account-benefits-types-and-account-opening-procedure/';
export const BOB_SAVINGS_ACCOUNT_URL =
  'https://upstox.com/savings-account/bank-of-baroda-savings-account/';
export const CITI_BANK_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/citibank-savings-account/';
export const SBI_IMPS_URL =
  'https://upstox.com/savings-account/sbi-immediate-payment-service-imps-transfer-money-instantly-safely/';

export const FEDERAL_BANK_NET_BANKING_URL =
  'https://upstox.com/savings-account/fednet-federal-bank-net-banking/';
export const SAVING_ACCOUNT_INTEREST_RATE_URL =
  'https://upstox.com/savings-account/savings-account-interest-rates/';
export const AXIS_BANK_SALARY_ACCOUNT_URL =
  'https://upstox.com/savings-account/axis-bank-salary-account/';
export const UNION_BANK_OF_INDIA_SAVINGS_ACCOUNTS_URL =
  'https://upstox.com/savings-account/union-bank-of-india-ubi-savings-account/';
export const INDUSIND_BANK_SAVING_ACCOUNT_URL =
  'https://upstox.com/savings-account/indusind-bank-savings-account/';
export const RBL_BANK_SAVINGS_ACCOUNT_URL =
  'https://upstox.com/savings-account/rbl-bank-savings-account/';
export const INDIAN_BANK_SB_ACCOUNT_URL =
  'https://upstox.com/savings-account/indian-bank-savings-account/';

// Saving Schemes

export const MGNREGA_URL = 'https://upstox.com/saving-schemes/mgnrega-nrega/';
export const DIGITAL_SEVA_PORTAL_URL =
  'https://upstox.com/saving-schemes/csc-digital-seva-portal/';
export const SUKANYA_SAMRIDDHI_YOJANA_URL =
  'https://upstox.com/saving-schemes/ssy-sukanya-samriddhi-yojana-interest-rate/';
export const EPF_CLAIM_STATUS_URL =
  'https://upstox.com/saving-schemes/epfo-claim-status-check-online/';
export const PMJJBY_URL =
  'https://upstox.com/saving-schemes/pradhan-mantri-jeevan-jyoti-bima-yojana-pmjjby-scheme-details/';
export const PRAN_URL =
  'https://upstox.com/saving-schemes/pran-permanent-retirement-account-number-meaning/';
export const KISAN_VIKAS_PATRA_URL =
  'https://upstox.com/saving-schemes/kvp-kisan-vikas-patra/';
export const PRADHAN_MANTRI_JAN_DHAN_YOJNA_URL =
  'https://upstox.com/saving-schemes/what-is-pradhan-mantri-jan-dhan-yojana-pmjdy/';

export const UAN_MEMBER_PORTAL_URL =
  'https://upstox.com/saving-schemes/uan-member-portal/';
export const PMJAY_URL =
  'https://upstox.com/saving-schemes/ayushman-bharat-pradhan-mantri-jan-arogya-yojana-pmjay/';
export const ATAL_PENSION_YOJANA_URL =
  'https://upstox.com/saving-schemes/atal-pension-yojana-apy/';
export const FORM_15G = 'https://upstox.com/saving-schemes/form-15g-download/';
export const SENIOR_CITIZEN_SAVINGS_SCHEME_URL =
  'https://upstox.com/saving-schemes/senior-citizen-savings-scheme-scss/';
export const KANYA_SUMANGALA_YOJANA =
  'https://upstox.com/saving-schemes/mukhyamantri-kanya-sumangala-yojana-ksy/';
export const GPF_URL =
  'https://upstox.com/saving-schemes/gpf-general-provident-fund-for-government-employees/';
export const POST_OFFICE_MONTHLY_INCOME_SCHEME_URL =
  'https://upstox.com/saving-schemes/post-office-monthly-income-scheme-pomis/';

export const PM_KISAN_SAMMAN_NIDHI_YOJANA_URL =
  'https://upstox.com/saving-schemes/pmkisan-pm-kisan-samman-nidhi-yojana-online/';
export const PMAY_URL =
  'https://upstox.com/saving-schemes/pmay-pradhan-mantri-awas-yojana/';
export const PPF_INTEREST_RATE_URL =
  'https://upstox.com/saving-schemes/public-provident-fund-ppf-interest-rate/';
export const BETI_BACHAO_BETI_PADHAO_URL =
  'https://upstox.com/saving-schemes/beti-bachao-beti-padhao-yojana-bbbp-scheme/';
export const PMSBY_URL =
  'https://upstox.com/saving-schemes/pradhan-mantri-suraksha-bima-yojana-pmsby/';
export const EPF_BALANCE_URL =
  'https://upstox.com/saving-schemes/epf-balance-check-online/';
export const NSC_INTEREST_RATE_URL =
  'https://upstox.com/saving-schemes/national-saving-certificate-nsc-interest-rates/';

export const NPS_URL =
  'https://upstox.com/saving-schemes/nps-national-pension-scheme-india/';
export const PMGAY_URL =
  'https://upstox.com/saving-schemes/pradhan-mantri-gramin-awaas-yojana-pmayg/';
export const PMMVY_URL =
  'https://upstox.com/saving-schemes/pmmvy-pradhan-mantri-matru-vandana-yojana-online-registration/';
export const GRATUITY_URL =
  'https://upstox.com/saving-schemes/gratuity-meaning/';
export const POST_OFFICE_SAVING_SCHEMES_URL =
  'https://upstox.com/saving-schemes/post-office-saving-schemes/';
export const EPF_WITHDRAWAL_ONLINE_URL =
  'https://upstox.com/saving-schemes/employee-provident-fund-epf-withdrawal-online/';
export const EPF_INTEREST_RATE_URL =
  'https://upstox.com/saving-schemes/employee-provident-fund-epf-interest-rate-2022-23/';

// IPO Learn.

export const IPO_ALLOTMENT_STATUS_URL =
  'https://upstox.com/learning-center/ipo/ipo-allotment-status/';
export const IPO_SUBSCRIPTION_URL =
  'https://upstox.com/learning-center/ipo/what-is-ipo-subscription-and-process-of-how-to-subscribe-for-an-ipo/';
export const DPO_URL =
  'https://upstox.com/learning-center/ipo/what-is-dpo-and-how-is-it-different-from-ipo/';
export const IPO_LEARN_URL =
  'https://upstox.com/learning-center/ipo/what-is-ipo-cycle-explain-the-process/';
export const HOW_TO_APPLY_FOR_IPO_URL =
  'https://upstox.com/learning-center/ipo/how-to-apply-for-an-ipo/';
export const CUT_OFF_PRICE_IN_IPO_URL =
  'https://upstox.com/learning-center/ipo/what-is-cut-off-price-in-ipo/';
export const BENEFITS_OF_IPO_URL =
  'https://upstox.com/learning-center/ipo/how-to-invest-in-ipo-and-benefits-of-investing-in-ipos/';
export const DIRECT_LISTING_VS_IPO_URL =
  'https://upstox.com/learning-center/ipo/differences-between-ipo-direct-listing/';

export const FLIPPING_URL =
  'https://upstox.com/learning-center/ipo/what-is-flipping-in-ipo/';
export const SME_IPO_URL =
  'https://upstox.com/learning-center/ipo/what-is-sme-ipo/';
export const IPO_CALENDER_URL =
  'https://upstox.com/learning-center/ipo/ipo-calendar-india-year-2022-2023/';
export const IMPORTANCE_OF_CAPITAL_BUDGETING_URL =
  'https://upstox.com/learning-center/ipo/how-is-an-ipo-initial-public-offering-priced/';
export const PRICE_BAND_URL =
  'https://upstox.com/learning-center/ipo/price-band-in-ipo/';
export const IPO_PRICE_URL =
  'https://upstox.com/learning-center/ipo/how-is-ipo-priced-or-valued/';
export const IPO_LOCK_URL =
  'https://upstox.com/learning-center/ipo/what-is-the-lock-in-period-in-ipo/';
export const FIXED_PRICE_ISSUE_URL =
  'https://upstox.com/learning-center/ipo/what-is-the-difference-between-fixed-price-issue-book-building-issue/';

export const GREY_MARKET_PREMIUM_PREMIUM_URL =
  'https://upstox.com/learning-center/ipo/what-is-grey-market-premium-gmp-in-ipo/';
export const FACE_VALUE_URL =
  'https://upstox.com/learning-center/ipo/what-is-face-value-in-ipo/';
export const GREENSHOE_OPTION_URL =
  'https://upstox.com/learning-center/ipo/what-is-greenshoe-option-in-ipo/';
export const INVESTING_IN_IPO_URL =
  'https://upstox.com/learning-center/ipo/things-to-check-before-investing-in-ipos/';
export const LOT_SIZE_URL =
  'https://upstox.com/learning-center/ipo/what-is-lot-size-in-an-ipo/';
export const PRE_IPO_URL =
  'https://upstox.com/learning-center/ipo/what-is-pre-ipo-investing/';
export const IPO_REQUIREMENT_URL =
  'https://upstox.com/learning-center/ipo/what-are-ipo-requirements-in-india/';

export const FPO_URL =
  'https://upstox.com/learning-center/ipo/what-is-fpo-in-ipo-and-how-to-apply/';
export const IPO_LISTING_URL =
  'https://upstox.com/learning-center/ipo/what-is-ipo-listing/';
export const OFS_URL =
  'https://upstox.com/learning-center/ipo/what-is-ofs-in-ipo-and-how-to-apply/';
export const ISSUE_PRUCE_URL =
  'https://upstox.com/learning-center/ipo/what-is-the-issue-price-in-ipo/';
export const OVERSUBSCRIPTION_URL =
  'https://upstox.com/learning-center/ipo/what-is-oversubscription-in-ipo/';
export const LUNCH_IPO_URL =
  'https://upstox.com/learning-center/ipo/how-to-launch-ipo-in-india/';
export const BOOK_BUILDING_IPO_URL =
  'https://upstox.com/learning-center/ipo/what-is-book-building/';

// F&O Learning

export const DERIVATIVE_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-a-derivative/';
export const FUTURES_AND_OPTIONS_LEARN_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-futures-and-options/';
export const FUTURES_TRADING_LEARN_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-futures-trading/';
export const OVER_THE_COUNTER_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-over-the-counter-otc-options/';
export const UNDERLAYING_ASSET_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-an-underlying-asset/';
export const CALL_WRITING_URL = 'Call Writing';
export const OPTIONS_TRADER_URL =
  'https://upstox.com/learning-center/futures-and-options/how-to-be-a-successful-options-trader/';
export const BERMUDA_OPTIONS =
  'https://upstox.com/learning-center/futures-and-options/what-are-bermuda-options/';

export const OPTIONS_TRADING_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-options-trading/';
export const CALL_OPTION_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-call-option/';
export const COMMODITY_FUTURES_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-commodity-futures/';
export const DERIVATIVE_TRADING_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-derivative-trading/';
export const SPOT_PRICE_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-spot-price/';
export const EXCHAGNE_TRADED_DERIVATIVES_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-exchange-traded-derivatives/';
export const COMMODITY_OPTIONS_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-commodity-options/';
export const DIAGONAL_SPREAD_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-a-diagonal-spread-and-how-does-it-work/';

export const INDEX_FUTUERS_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-index-futures/';
export const COLLATERALIZED_DEBT_OBLIGATIONS_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-collateralized-debt-obligations/';
export const STRICKE_PRICE_URL =
  'https://upstox.com/learning-center/futures-and-options/strike-price/';
export const SHORT_COVERING_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-short-covering-meaning/';
export const OPTION_PREMIUM_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-option-premium/';
export const PROTECTIVE_PUT_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-a-protective-put/';
export const HEDGING_WITH_FUTURES_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-hedging-with-futures/';

export const OPTION_TRADING_STRATEGIES_URL =
  'https://upstox.com/learning-center/futures-and-options/options-trading-strategies-vertical-spreads-and-synthetic-option-spreads/';
export const FORWARD_CONTRACT_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-a-forward-contract/';
export const LTP_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-ltp-in-share-market/';
export const EQUITY_DERIVATIVES_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-equity-derivatives/';
export const HEDGING_STRATEGY_URL =
  'https://upstox.com/learning-center/futures-and-options/what-is-a-hedging-strategy/';
export const CASH_SECURED_PUTS_URL =
  'https://upstox.com/learning-center/futures-and-options/what-are-cash-secured-puts/';
export const PUT_WRITING_URL =
  'https://upstox.com/learning-center/futures-and-options/put-writing-strategies/';

export const EXCLUDED_LIC_IPO_IDS = [
  '42cb1060d6504ae9930f8c595b35a019',
  '29431ebeba764fa29687c5f4a45c2e67',
];
